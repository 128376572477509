<template>
  <div>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-list dense max-width="250px">
                <v-subheader>Animal Types</v-subheader>
                <v-list-item-group v-model="animalTypes" color="primary">
                  <v-list-item inactive v-for="(item, i) in animalTypes" :key="i">
                    <v-list-item-avatar>
                      <span style="font-size: 24px">{{ item.emoji }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn @click.stop="clickAnimalType(item)">Select</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col>
              <v-data-table v-if="showPiecesTable" :headers="headers" :items="items">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Pieces of one {{ piecesTableCaption }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="showAddEditDialog" @keydown.esc="showAddDialog = false; showEditDialog = false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn data-cy="addItem" color="primary" v-bind="attrs" v-on="on"
                          @click="showAddDialog = true">Add Piece</v-btn>
                      </template>
                      <v-card>
                        <v-form v-model="addEditFormValid" @submit.prevent="submitAddEditForm">
                          <v-card-title>
                            <span v-if="showAddDialog" class="text-h5">Add Item</span>
                            <span v-if="showEditDialog" class="text-h5">Edit Item</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col>
                                  <v-text-field data-cy="name" v-model="editedItem.name" label="Name" required>
                                  </v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field data-cy="weight" v-model="editedItem.weight" label="Weight (g)" required>
                                  </v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field data-cy="quantity" v-model="editedItem.quantity" label="Quantity"
                                    required>
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field data-cy="minimumPriceInCents" v-model="editedItem.minimumPriceInCents"
                                    label="Minimum" required>
                                  </v-text-field>
                                  <v-text-field data-cy="priceInCents" v-model="editedItem.priceInCents"
                                    label="Price in cents" required>
                                  </v-text-field>
                                  <v-text-field data-cy="maximumPriceInCents" v-model="editedItem.maximumPriceInCents"
                                    label="Maximum" required>
                                  </v-text-field>
                                </v-col>
                                <v-col>
                                  <v-checkbox v-model="editedItem.triggerPiece" label="Trigger piece"></v-checkbox>
                                  <v-checkbox v-model="editedItem.requiresCuring" label="Requires curing"></v-checkbox>
                                  <v-checkbox v-model="editedItem.willBeTransformed"
                                    label="Will be transformed"></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn data-cy="addItemClose" @click="showAddDialog = false; showEditDialog = false"> Cancel
                            </v-btn>
                            <v-btn v-if="showAddDialog" data-cy="addItemSave" color="primary" type="submit"> Save
                            </v-btn>
                            <v-btn v-if="showEditDialog" data-cy="editItemSave" color="primary" type="submit"> Update
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click.stop="editPiece(item)">
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ButcherPieces',
  components: {},

  data: () => ({
    animalTypes: [],
    headers: [
      { text: 'Name', align: 'start', value: 'name' },
      { text: 'Weight', align: 'start', value: 'weight' },
      { text: 'Quantity', align: 'start', value: 'quantity' },
      { text: 'Price', align: 'start', value: 'priceInCents' },
      { text: 'Trigger', align: 'start', value: 'triggerPiece' },
      { text: 'Actions', align: 'start', value: 'actions' }
    ],
    items: [],
    editedItem: {},
    selectedAnimalType: null,
    piecesTableCaption: '',
    showPiecesTable: false,
    showAddDialog: false,
    showEditDialog: false,
    addEditFormValid: false
  }),

  computed: {
    showAddEditDialog () {
      return this.showAddDialog || this.showEditDialog
    }
  },

  beforeMount () {
    this.loadAnimalTypes()
  },

  methods: {
    loadMeatPieces (animalType) {
      fetch(`/api/butchershop/pieces/${animalType}`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.items = data.meatPieces
        })
        .catch(() => {
          this.items = []
        })
    },

    loadAnimalTypes () {
      fetch('/api/animals/types', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          data.forEach(item => {
            switch (item) {
              case 'PIG':
                this.animalTypes.push({ type: item, text: item, emoji: '🐖' })
                break
              case 'CATTLE':
                this.animalTypes.push({ type: item, text: item, emoji: '🐄' })
                break
              case 'SHEEP':
                this.animalTypes.push({ type: item, text: item, emoji: '🐑' })
                break
              case 'HORSE':
                this.animalTypes.push({ type: item, text: item, emoji: '🐎' })
                break
              case 'GOAT':
                this.animalTypes.push({ type: item, text: item, emoji: '🐐' })
                break
              case 'CHICKEN':
                this.animalTypes.push({ type: item, text: item, emoji: '🐓' })
                break
              default: this.animalTypes.push({ type: item, text: item, emoji: '' })
            }
          })
        })
    },

    clickAnimalType (animalType) {
      this.piecesTableCaption = animalType.text
      this.selectedAnimalType = animalType.type
      this.loadMeatPieces(animalType.type)
      this.showPiecesTable = true
    },

    addItem () {
      const item = {
        name: this.editedItem.name,
        weight: this.editedItem.weight,
        quantity: this.editedItem.quantity,
        animalType: this.selectedAnimalType,
        priceInCents: this.editedItem.priceInCents,
        minimumPriceInCents: this.editedItem.minimumPriceInCents,
        maximumPriceInCents: this.editedItem.maximumPriceInCents,
        triggerPiece: this.editedItem.triggerPiece,
        requiresCuring: this.editedItem.requiresCuring,
        willBeTransformed: this.editedItem.willBeTransformed
      }

      fetch('/api/butchershop/pieces', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(item)
      })
        .then(() => {
          this.loadMeatPieces(this.selectedAnimalType)
          this.showAddDialog = false
          this.editedItem = {}
        })
    },

    editPiece (item) {
      this.editedItem = item
      this.showEditDialog = true
    },

    updateItem () {
      fetch(`/api/butchershop/pieces/${this.editedItem.pieceId}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify(this.editedItem)
      })
        .then(() => {
          this.loadMeatPieces(this.selectedAnimalType)
          this.showEditDialog = false
          this.editedItem = {}
        })
    },

    submitAddEditForm () {
      if (this.showAddDialog) { this.addItem() }

      if (this.showEditDialog) { this.updateItem() }
    }
  }
}
</script>
